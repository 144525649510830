import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsOfUse: React.FC = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Terms of Use
            </Typography>
            <Typography paragraph>
                By using our app, you agree to the following terms and conditions.
            </Typography>
            <Typography paragraph>
                <strong>1. Acceptance of Terms</strong><br />
                By accessing or using our app, you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree to these terms, please do not use our app.
            </Typography>
            <Typography paragraph>
                <strong>2. User Eligibility</strong><br />
                You must be at least 18 years old to use our app. By using the app, you represent and warrant that you meet this age requirement and are legally able to enter into contracts.
            </Typography>
            <Typography paragraph>
                <strong>3. Account Responsibility</strong><br />
                You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
            </Typography>
            <Typography paragraph>
                <strong>4. User Conduct</strong><br />
                You agree to use the app in accordance with all applicable laws and regulations. You must not engage in any illegal or inappropriate behavior, including but not limited to harassment, impersonation, or false representation.
            </Typography>
            <Typography paragraph>
                <strong>5. Content</strong><br />
                You are solely responsible for any content you post on the app. We reserve the right to remove any content that we deem inappropriate or that violates our policies.
            </Typography>
            <Typography paragraph>
                <strong>6. Privacy</strong><br />
                Your use of the app is governed by our Privacy Policy, which describes how we collect, use, and protect your personal information.
            </Typography>
            <Typography paragraph>
                <strong>7. Termination</strong><br />
                We reserve the right to terminate or suspend your account at our discretion, with or without cause, and with or without notice, if we believe you have violated these Terms of Use.
            </Typography>
            <Typography paragraph>
                <strong>8. Disclaimer</strong><br />
                Our app is provided on an "as is" and "as available" basis. We make no warranties or representations regarding the accuracy or reliability of the app or its content.
            </Typography>
            <Typography paragraph>
                <strong>9. Limitation of Liability</strong><br />
                To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of the app.
            </Typography>
            <Typography paragraph>
                <strong>10. Changes to Terms</strong><br />
                We may update these Terms of Use from time to time. Any changes will be posted on this page, and your continued use of the app constitutes your acceptance of the revised terms.
            </Typography>
            <Typography paragraph>
                <strong>11. Contact Us</strong><br />
                If you have any questions or concerns about these Terms of Use, please contact us at [Your Contact Information].
            </Typography>
        </Container>
    );
};

export default TermsOfUse;
