import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container sx={{ py: 8 }}>
            {/* Page Header */}
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>

            {/* Privacy Policy Content */}
            <Typography variant="body1" paragraph>
                Welcome to [App Name]! We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at [contact email].
            </Typography>

            <Typography variant="body1" paragraph>
                When you use our app, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important.
            </Typography>

            <Typography variant="h5" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
                We collect personal information that you voluntarily provide to us when registering at the app, expressing an interest in obtaining information about us or our products and services, when participating in activities on the app (such as posting messages in our online forums or entering competitions, contests, or giveaways) or otherwise contacting us.
            </Typography>

            <Typography variant="h5" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
                We use personal information collected via our app for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
            </Typography>

            <Typography variant="body1" paragraph>
                We use the information we collect or receive:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>To facilitate account creation and logon process.</li>
                <li>To send you marketing and promotional communications.</li>
                <li>To send administrative information to you.</li>
                <li>To fulfill and manage your orders.</li>
                <li>To post testimonials.</li>
                <li>To deliver targeted advertising to you.</li>
                <li>To administer prize draws and competitions.</li>
                <li>To request feedback.</li>
                <li>To protect our services.</li>
                <li>To enforce our terms, conditions, and policies.</li>
                <li>To respond to legal requests and prevent harm.</li>
                <li>To manage user accounts.</li>
                <li>To deliver services to the user.</li>
                <li>To respond to user inquiries/offer support to users.</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                3. Will Your Information Be Shared With Anyone?
            </Typography>
            <Typography variant="body1" paragraph>
                We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
            </Typography>

            <Typography variant="h5" gutterBottom>
                4. How Long Do We Keep Your Information?
            </Typography>
            <Typography variant="body1" paragraph>
                We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
            </Typography>

            <Typography variant="h5" gutterBottom>
                5. How Do We Keep Your Information Safe?
            </Typography>
            <Typography variant="body1" paragraph>
                We aim to protect your personal information through a system of organizational and technical security measures.
            </Typography>

            <Typography variant="h5" gutterBottom>
                6. What Are Your Privacy Rights?
            </Typography>
            <Typography variant="body1" paragraph>
                In some regions (like the European Economic Area), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
            </Typography>

            <Typography variant="h5" gutterBottom>
                7. Controls for Do-Not-Track Features
            </Typography>
            <Typography variant="body1" paragraph>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
            </Typography>

            <Typography variant="h5" gutterBottom>
                8. Do We Make Updates to This Policy?
            </Typography>
            <Typography variant="body1" paragraph>
                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.
            </Typography>

            <Typography variant="h5" gutterBottom>
                9. How Can You Contact Us About This Policy?
            </Typography>
            <Typography variant="body1" paragraph>
                If you have questions or comments about this policy, you may email us at help@halallove.app or by post to:
                <br />
                moons+stars llc
                <br />
                1113 Colson Rd
                <br />
                Plant City, FL, Zip 33567
            </Typography>
        </Container>
    );
};

export default PrivacyPolicy;
