import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/roboto';


const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
});

// Get the root element from the DOM
const rootElement = document.getElementById('root');
if (rootElement) {
    // Create a root using React 18's createRoot API
    const root = ReactDOM.createRoot(rootElement);

    // Render the app inside the root
    root.render(
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
