import React, { useState } from 'react';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

const RequestDelete: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        reason: ''
    });

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_0pi5lrq', 'template_70wcvkw', e.target as HTMLFormElement, 'olL62jGPfyVI0GpQx')
            .then((result) => {
                alert('Your request has been sent and will be completed within 24 hours.');
                setTimeout(() => {
                    navigate('/');
                }, 3000); // Redirect after 3 seconds
            }, (error) => {
                alert('An error occurred, please try again.');
            });
    };

    return (
        <Container maxWidth="sm" sx={{ py: 5 }}>
            <Box
                sx={{
                    backgroundColor: '#FAAB19',
                    padding: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                    boxShadow: 3
                }}
            >
                <Typography variant="h4" gutterBottom style={{ color: '#FFFFFF' }}>
                    Request Account Deletion
                </Typography>
                <Typography variant="body1" gutterBottom style={{ color: '#FFFFFF' }}>
                    We're sorry to see you go. Please provide your details below to request account deletion.
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Box sx={{ mt: 3 }}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            required
                            sx={{ mb: 2, backgroundColor: '#FFFFFF', borderRadius: 1 }}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            required
                            sx={{ mb: 2, backgroundColor: '#FFFFFF', borderRadius: 1 }}
                        />
                        <TextField
                            label="Reason for Deletion"
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={4}
                            required
                            sx={{ mb: 2, backgroundColor: '#FFFFFF', borderRadius: 1 }}
                        />
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ backgroundColor: '#354856', color: '#FFFFFF', mt: 2 }}
                        fullWidth
                    >
                        Submit Request
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default RequestDelete;
