import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardContent, CardMedia, Box } from '@mui/material';
import { Apple as AppleIcon, Android as AndroidIcon } from '@mui/icons-material';
import {Link} from "react-router-dom";

// Custom colors based on your palette
const primaryColor = '#FAAB19';
const headerColor = '#354856';
const buttonSelectedColor = '#94B5B3';
const whiteColor = '#FFFFFF';

const Home: React.FC = () => {
    return (
        <div>
            {/* Header / Navigation Bar */}
            <AppBar position="static" sx={{ backgroundColor: headerColor }}>
                <Toolbar>
                    <img
                        src="hl-logo.png" // Replace this with the path to your logo
                        alt="Halal Love"
                        style={{ width: '200px', }} // Adjust height and margin as needed
                    />

                    <Box sx={{ flexGrow: 1 }} />

                    <Button color="inherit" sx={{ color: whiteColor }}>About</Button>
                    <Button color="inherit" sx={{ color: whiteColor }}>Features</Button>
                    <Button color="inherit" sx={{ color: whiteColor }}>Success Stories</Button>
                    <Button color="inherit" sx={{ color: whiteColor }}>Help</Button>
                    <Button variant="contained" sx={{ ml: 2, backgroundColor: primaryColor, color: whiteColor }}>
                        Sign Up
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Hero Section */}
            <Container sx={{ py: 8, textAlign: 'center', backgroundColor: primaryColor, color: whiteColor }}>
                <Box mb={4}>
                <img
                    src="hl-logo.png" // Replace this with the path to your logo
                    alt="Halal Love"
                    style={{ width: '400px', }} // Adjust height and margin as needed
                />
                </Box>
                <Box mb={4}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }} paragraph>
                    Halal Love, New Beginnings: For Divorced and Widowed Muslims Singles.
                </Typography>
                </Box>

                <Box component="img" src="app_store.svg" alt="Image 1" sx={{ width: '100px', mr: 2 }} />
                <Box component="img" src="google_play.png" alt="Image 2" sx={{ width: '100px' }} />

                <Box sx={{ mt: 4 }}>
                    <img src="/hl-family-photo.webp" alt="Family" style={{ width: '100%', maxWidth: '500px' }} />
                </Box>
            </Container>

            {/* Features Section */}
            <Container sx={{ py: 8 }}>
                <Typography variant="h4" gutterBottom textAlign="center" sx={{ color: headerColor, fontWeight: 'bold' }}>
                    Features Designed to Simplify the Journey
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ backgroundColor: whiteColor }}>
                            <CardMedia
                                component="img"
                                image="/1.jpg"
                                alt="Feature 1"
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ color: headerColor,  fontWeight: 'bold'  }}>
                                   Vetted People
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold' }}>
                                    Our team vets every profile to ensure a safe and peaceful experience.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ backgroundColor: whiteColor }}>
                            <CardMedia
                                component="img"
                                image="/2.jpg"
                                alt="Feature 2"
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ color: headerColor, fontWeight: 'bold'   }}>
                                    Free and secure messaging.
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold' }}>
                                    We never block core features behind a paywall.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ backgroundColor: whiteColor }} >
                            <CardMedia
                                component="img"
                                image="/4.jpg"
                                alt="Feature 3"
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ color: headerColor, fontWeight: 'bold'  }}>
                                   Features to to filter bad actors.
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold' }}>
                                    So you're always interacting with real people.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            {/* Success Stories / Testimonials */}


            {/* How It Works Section */}
            <Container sx={{ py: 8 }}>
                <Typography variant="h4" gutterBottom textAlign="center" sx={{ color: headerColor }}>
                    How It Works
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{fontWeight: 'bold', color: headerColor }}>Step 1</Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold',color: headerColor }}>
                            Sign up and create your profile in a series of easy steps.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold',color: headerColor }}>Step 2</Typography>
                        <Typography variant="body2" sx={{fontWeight: 'bold', color: headerColor }}>
                            Browse through potential matches and find someone compatible.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{fontWeight: 'bold', color: headerColor }}>Step 3</Typography>
                        <Typography variant="body2" sx={{fontWeight: 'bold', color: headerColor }}>
                            Start chatting and start your marriage journey!
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/* Call to Action Section */}
            <Container sx={{ py: 8, textAlign: 'center', backgroundColor: primaryColor, color: whiteColor }}>
                <Typography variant="h4" gutterBottom>
                    Ready to Find Your Match?
                </Typography>
                <Button variant="contained" startIcon={<AppleIcon />} sx={{ m: 1, backgroundColor: buttonSelectedColor, color: headerColor }}>
                    Download on the App Store
                </Button>
                <Button variant="contained" startIcon={<AndroidIcon />} sx={{ m: 1, backgroundColor: buttonSelectedColor, color: headerColor }}>
                    Get it on Google Play
                </Button>
            </Container>

            {/* Footer */}
            <Box sx={{ py: 4, backgroundColor: headerColor, color: whiteColor, textAlign: 'center' }}>
                <Container>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Halal Love. All rights reserved.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <Button color="inherit" sx={{ textTransform: 'none', color: whiteColor }} component={Link} to="/privacy-policy">
                            Privacy Policy
                        </Button>
                        |
                        <Button color="inherit" sx={{ textTransform: 'none', color: whiteColor, ml: 1 }} component={Link} to="/terms-of-use">
                            Terms of Use
                        </Button>
                        |
                        <Button color="inherit" sx={{ textTransform: 'none', color: whiteColor, ml: 1 }}>
                            Help
                        </Button>
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

export default Home