import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import '@fontsource/roboto';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import RequestDelete from './components/RequestDelete';


const App: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/request-delete" element={<RequestDelete />} />
        </Routes>
      </Router>
  );
};

export default App;
